import React, { useState } from "react";
import { Link } from "react-router-dom";
import { date } from "../helpers";
import { useParams } from "react-router-dom";

const MessageItem = ({
  heading = false,
  loading = false,
  item = false,
  community_slug,
  user = {},
}) => {
  const { member_id } = useParams();
  if (heading === true) {
    return (
      <div className="grid grid-cols-10 font-semibold mb-7 gap-6 px-2">
        <div className="col-span-7">{user?.name}'s Messages</div>
        <div className="col-span-3 text-center">Action</div>
      </div>
    );
  } else if (loading) {
    return (
      <div className="grid grid-cols-10 py-3 gap-6 px-2 rounded-lg animate-pulse">
        <div className="col-span-7">
          <div className="h-4 w-52 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-3 flex">
          <div className="h-4 w-32 bg-gray-400 rounded-md mx-auto"></div>
          <div className="h-4 w-32 bg-gray-400 rounded-md mx-auto"></div>
        </div>
      </div>
    );
  } else if (item) {
    return (
      <div
        className={`grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-10 py-3 gap-6 px-2 rounded-lg items-center`}
      >
        <div className="lg:col-span-7 flex gap-5 items-center">
          <Link
            to={`/${community_slug}/members/${member_id}/message/${item.id}/community`}
            className="text-lg break-words lg:w-auto"
          >
            {item.name}
          </Link>
          <div className="text-gray-400 text-sm">{date(item.created_at)}</div>
        </div>
        <div className="lg:col-span-3 flex justify-end lg:justify-center items-center gap-5">
          <Link
            to={`/${community_slug}/members/${member_id}/message/${item.id}/community`}
          >
            View Messages
          </Link>
        </div>
      </div>
    );
  }
};

export default MessageItem;
