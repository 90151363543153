import React, { useState, useContext } from "react";
import { signup } from "../api/auth";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Signup = () => {
  const { authToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Community name is required")
      .min(3, "Community name must be at least 3 characters")
      .max(20, "Community name must be at most 20 characters"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(50, "Password must be at most 50 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      )
      .required("Password is required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    total_member: Yup.number()
      .min(1, "Total members must be greater than or equal to 1")
      .max(10000)
      .required("Total members is required"),
  });

  const initialValues = {
    name: "",
    email: "",
    total_member: "",
    password: "",
    confirm_password: "",
  };

  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      const response = await signup(values);
      console.log(response);
      if (response?.success === true) {
        navigate("/login");
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setValues }) => (
          <Form>
            {!authToken && (
              <h2 className="text-3xl text-center mb-5 md:mb-10">Let’s get started!</h2>
            )}
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Community Name
              </label>
              <Field
                type="text"
                name="name"
                id="name"
                className="input input-bordered"
              />
              <ErrorMessage name="name" className="error" component="div" />
            </div>
            <div className="mb-4">
              <label
                htmlFor="username"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Email
              </label>
              <Field
                type="text"
                name="email"
                id="email"
                className="input input-bordered"
              />
              <ErrorMessage name="email" className="error" component="div" />
            </div>
            <div className="mb-4">
              <label
                htmlFor="total_member"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Total members
              </label>
              <Field
                type="number"
                min={1}
                step={1}
                name="total_member"
                id="total_member"
                className="input input-bordered"
                onKeyPress={(event) => {
                  if (event.charCode < 48) {
                    event.preventDefault();
                  }
                }}
              />
              <ErrorMessage
                name="total_member"
                className="error"
                component="div"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="password"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Password
              </label>
              <Field
                name="password"
                type="password"
                id="password"
                autoComplete="false"
                className="input input-bordered"
              />
              <ErrorMessage name="password" className="error" component="div" />
            </div>
            <div className="mb-6">
              <label
                htmlFor="confirm_password"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Confirm Password
              </label>
              <Field
                name="confirm_password"
                type="password"
                id="confirm_password"
                autoComplete="false"
                className="input input-bordered"
              />
              <ErrorMessage
                name="confirm_password"
                className="error"
                component="div"
              />
            </div>
            {/* {errorMessage && <p className="text-red-500 text-sm mb-4">{errorMessage}</p>} */}
            <div
              className={`flex items-center ${
                !authToken ? "justify-between" : "justify-end"
              }`}
            >
              {!authToken && <Link to="/login">Login</Link>}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading} // Disable button while loading
              >
                {isLoading ? "Please wait..." : "Create"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Signup;
