import React, { useState } from "react";
import { Link } from "react-router-dom";
import { destoryUSer } from "../api/communities";

const MemberItem = ({
  heading = false,
  loading = false,
  item = false,
  fetchData,
  community_slug,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const destoryCommunity = async () => {
    try {
      if (window.confirm(`Are you sure to delete ${item.name} user?`)) {
        setIsLoading(true);
        await destoryUSer(item.id, community_slug);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      fetchData();
    }
  };

  if (heading) {
    return (
      <div className="hidden lg:grid grid-cols-10 font-semibold mb-7 gap-6 px-2">
        <div className="col-span-7">Member Name</div>
        <div className="col-span-3 text-center">Action</div>
      </div>
    );
  } else if (loading) {
    return (
      <div className="grid grid-cols-10 py-3 gap-6 px-2 rounded-lg animate-pulse">
        <div className="col-span-7">
          <div className="h-4 w-52 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-3 flex">
          <div className="h-4 w-32 bg-gray-400 rounded-md mx-auto"></div>
          <div className="h-4 w-32 bg-gray-400 rounded-md mx-auto"></div>
        </div>
      </div>
    );
  } else if (item) {
    return (
      <div
        className={`grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-10 py-3 gap-0 lg:gap-6 px-2 rounded-lg items-center ${
          isLoading === true
            ? "animate-pulse pointer-events-none cursor-wait"
            : ""
        }`}
      >
        <div className="lg:col-span-7 flex">
          <div className="lg:hidden w-48">Member Name:</div>
          <Link
            to={`/${community_slug}/members/${item.id}/messages`}
            className="w-full"
          >
            {item.name}
          </Link>
        </div>
        <div className="lg:col-span-3 flex justify-between lg:justify-end lg:justify-center items-center gap-5">
          <Link to={`/${community_slug}/members/${item.id}/messages`}>
            View Messages
          </Link>
          <button
            className="btn text-error btn-ghost"
            onClick={() => destoryCommunity()}
          >
            Remove
          </button>
        </div>
      </div>
    );
  }
};

export default MemberItem;
