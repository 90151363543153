import React, { useState } from "react";
import SearchSVG from "../assets/images/search.svg";

const Search = ({ search, nameSearch, setNameSearch }) => {
  return (
    <form onSubmit={search} className="col-span-2 w-full lg:max-w-fit">
      <div className="join border border-gray-300 w-full px-0">
        <input
          className="join-item input border-0"
          placeholder="Search"
          value={nameSearch}
          onChange={(e) => setNameSearch(e.target.value)}
        />

        <div className="indicator">
          <button className="btn join-item border-0">
            <img src={SearchSVG} alt="Search" className="h-6" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default Search;
