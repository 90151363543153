import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { useParams } from "react-router-dom";
import { index, userSelection, store } from "../../api/channels";
import ChannelItem from "../../components/ChannelItem";
import NoItems from "../../components/NoItems";
import Pagination from "../../components/Pagination";

const Channels = () => {
  const { community_slug } = useParams();
  const [data, setData] = useState(null);
  const [memberSelection, setMemberSelection] = useState(null);
  const [channelUses, setChannelUses] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [channel_name, setName] = useState("");
  const [manager, setManager] = useState("");
  const [members, setMembers] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);

  const fetchData = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await index({ community_slug });
      setData(response.data);

      const members = await userSelection({ community_slug });
      setMemberSelection(members.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await store({
        community_slug: community_slug,
        data: { channel_name, manager, members },
      });
      setIsLoading(false);
      clearform();
      fetchData();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const clearform = () => {
    setName("");
    setManager("");
    setMembers([]);
  };

  useEffect(() => {
    if (manager) {
      const users = memberSelection.filter((user) => user.value != manager);
      setChannelUses(users);
    }
  }, [manager]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* Create Channel */}

      <form
        onSubmit={handleSubmit}
        className={`grid-rows-4 lg:grid-rows-1 lg:grid-cols-12 gap-0 lg:gap-6 ${
          showCreateForm === false ? "hidden lg:grid" : ""
        }  `}
      >
        <div className="mb-4 lg:col-span-4">
          <label
            htmlFor="name"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Channel Name
          </label>
          <input
            type="text"
            id="name"
            value={channel_name}
            onChange={(e) => setName(e.target.value)}
            className="input input-bordered"
            required
            maxLength={35}
          />
        </div>
        <div className="mb-4 lg:col-span-3">
          <label
            htmlFor="username"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Select Manager
          </label>
          {memberSelection && memberSelection.length > 0 && (
            <select
              name="manager"
              id="manager"
              className="select select-bordered"
              value={manager}
              onChange={(e) => setManager(e.target.value)}
              required
            >
              <option value="">Select</option>
              {memberSelection.map((member) => {
                return (
                  <option value={member.value} key={member.value}>
                    {member.label}
                  </option>
                );
              })}
            </select>
          )}
        </div>
        <div className="mb-4 lg:col-span-3">
          <label
            htmlFor="total_member"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Members
          </label>
          {channelUses == null &&
            memberSelection &&
            memberSelection.length > 0 && (
              <MultiSelect
                options={memberSelection}
                value={members}
                onChange={setMembers}
                labelledBy="Select"
                className="select select-bordered px-0"
              />
            )}

          {channelUses && channelUses.length > 0 && (
            <MultiSelect
              options={channelUses}
              value={members}
              onChange={setMembers}
              labelledBy="Select"
              className="select select-bordered px-0"
            />
          )}
        </div>

        <div className={`flex items-center lg:col-span-2 justify-between`}>
          <button
            type="submit"
            className="btn btn-ghost text-error mt-2 lg:hidden"
            disabled={isLoading} // Disable button while loading
            onClick={() => setShowCreateForm(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary mt-2"
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? "Please wait..." : "Create"}
          </button>
        </div>
      </form>

      {showCreateForm === false && (
        <div className="lg:hidden text-right">
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => setShowCreateForm(true)}
          >
            Create Channel
          </button>
        </div>
      )}

      {/* Channel list */}
      <div className="mt-2 lg:my-10 lg:border border-gray-300 lg:p-3 rounded-lg striped">
        <ChannelItem heading={true} />
        {isLoading === true && (
          <>
            <ChannelItem loading={true} />
            <ChannelItem loading={true} />
          </>
        )}
        {isLoading === false &&
          (typeof data?.data === "undefined" || data?.data.length === 0) && (
            <NoItems />
          )}
        {isLoading === false &&
          typeof data?.data !== "undefined" &&
          data?.data.map((item) => (
            <ChannelItem
              key={item.id}
              item={item}
              fetchData={fetchData}
              community_slug={community_slug}
              member_selection={memberSelection}
            />
          ))}
      </div>
      {isLoading === false && typeof data?.data !== "undefined" && (
        <Pagination pagination={data?.pagination} onPageChange={fetchData} />
      )}
    </>
  );
};

export default Channels;
