import React, { useState } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { date } from "../helpers";
import { updateStatus } from "../api/requests";

const MemberItem = ({
  heading = false,
  loading = false,
  item = false,
  fetchData,
  community_slug,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const changeStatus = async (status) => {
    try {
      setIsLoading(true);
      const response = await updateStatus({
        community_slug: community_slug,
        id: item.id,
        status: status,
      });
      if (response.success === true) {
        fetchData();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (heading) {
    return (
      <div className="grid grid-cols-10 font-semibold mb-7 gap-6 px-2">
        <div className="col-span-7">Member Name</div>
        <div className="col-span-3 text-center">Action</div>
      </div>
    );
  } else if (loading) {
    return (
      <div className="grid grid-cols-10 py-3 gap-6 px-2 rounded-lg animate-pulse">
        <div className="col-span-7">
          <div className="h-4 w-52 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-3 flex">
          <div className="h-4 w-32 bg-gray-400 rounded-md mx-auto"></div>
          <div className="h-4 w-32 bg-gray-400 rounded-md mx-auto"></div>
        </div>
      </div>
    );
  } else if (item) {
    return (
      <div
        className={`grid grid-cols-10 py-3 gap-6 px-2 rounded-lg items-center ${
          isLoading === true
            ? "animate-pulse pointer-events-none cursor-wait"
            : ""
        }`}
      >
        <div className="col-span-5 lg:col-span-7 lg:flex gap-5 items-center">
          <span>{item.name}</span>
          <div className="text-gray-400 text-sm mt-2 lg:mt-0">
            {date(item.created_at)}
          </div>
        </div>
        <div className="col-span-5 lg:col-span-3 flex justify-center items-center gap-5">
          <button
            className="btn text-primary btn-ghost px-0 lg:px-3"
            onClick={() => changeStatus("approved")}
          >
            Approve
          </button>
          <button
            className="btn text-error btn-ghost px-0 lg:px-3"
            onClick={() => changeStatus("deny")}
          >
            Deny
          </button>
        </div>
      </div>
    );
  }
};

export default MemberItem;
