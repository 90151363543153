import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { members } from "../../api/communities";
import Search from "../../components/Search";
import MemberItem from "../../components/MemberItem";
import NoItems from "../../components/NoItems";
import Pagination from "../../components/Pagination";

const Members = () => {
  const { community_slug } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [nameSearch, setNameSearch] = useState("");

  const fetchData = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await members({
        slug: community_slug,
        name: nameSearch,
        page: page,
      });
      setData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const search = async (e) => {
    e.preventDefault();
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* Serch */}
      <div className="flex lg:justify-end gap-5">
        <Search
          search={search}
          nameSearch={nameSearch}
          setNameSearch={setNameSearch}
        />
      </div>

      {/* Members list */}
      <div className="my-3 lg:my-10 lg:border border-gray-300 lg:p-3 rounded-lg striped">
        <MemberItem heading={true} />
        {isLoading === true && (
          <>
            <MemberItem loading={true} />
            <MemberItem loading={true} />
          </>
        )}
        {isLoading === false &&
          (typeof data?.data === "undefined" || data?.data.length === 0) && (
            <NoItems />
          )}
        {isLoading === false &&
          typeof data?.data !== "undefined" &&
          data?.data.map((item) => (
            <MemberItem
              key={item.id}
              item={item}
              fetchData={fetchData}
              community_slug={community_slug}
            />
          ))}
      </div>
      {isLoading === false && typeof data?.data !== "undefined" && (
        <Pagination pagination={data?.pagination} onPageChange={fetchData} />
      )}
    </>
  );
};

export default Members;
