import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateStatus, destory } from "../api/communities";
import { date } from "../helpers";
import { setToLocalStorage } from "../utils/localStorage";

const CommunityItem = ({
  heading = false,
  loading = false,
  item = false,
  fetchData,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const changeStatus = async (status) => {
    try {
      setIsLoading(true);
      await updateStatus({
        id: item.id,
        status: status,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      fetchData();
    }
  };

  const destoryCommunity = async () => {
    try {
      if (window.confirm(`Are you sure to delete ${item.name} community?`)) {
        setIsLoading(true);
        await destory(item.id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      fetchData();
    }
  };

  const redirectToComunity = async (item) => {
    // await setToLocalStorage("community", item);
    // navigate(`/${item.slug}/members`);
  };

  if (heading) {
    return (
      <div className="hidden lg:grid grid-cols-11 font-semibold mb-7 gap-6 px-2">
        <div className="col-span-1">Community Code</div>
        <div className="col-span-2">Community Name</div>
        <div className="col-span-2">Email</div>
        <div className="col-span-1">No. of members</div>
        <div className="col-span-2">Domain</div>
        <div className="col-span-1">Created</div>
        <div className="col-span-2 text-center">Action</div>
      </div>
    );
  } else if (loading) {
    return (
      <div className="grid grid-cols-11 py-3 gap-6 px-2 rounded-lg animate-pulse">
        <div className="col-span-1">
          <div className="h-4 w-32 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-2">
          <div className="h-4 w-32 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-2 truncate">
          <div className="h-4 w-64 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-1">
          <div className="h-4 w-11 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-2">
          <div className="h-4 w-32 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-1">
          <div className="h-4 w-32 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-2">
          <div className="h-4 w-32 bg-gray-400 rounded-md mx-auto"></div>
        </div>
      </div>
    );
  } else if (item) {
    return (
      <div
        className={`grid lg:grid-cols-11 py-6 gap-2 lg:gap-6 px-2 rounded-lg ${
          isLoading === true
            ? "animate-pulse pointer-events-none cursor-wait"
            : ""
        }`}
      >
        <div className="lg:col-span-1 flex">
          <div className="lg:hidden w-full">Community Code:</div>
          <div className="truncate w-full block">{item.orrrg_code}</div>
        </div>
        <div className="lg:col-span-2 flex">
          <div className="lg:hidden w-full">Community Name:</div>
          <div
            onClick={() => redirectToComunity(item)}
            className="truncate w-full block"
          >
            {item.name}
          </div>
        </div>
        <div className="lg:col-span-2 flex text-gray-500">
          <div className="lg:hidden w-full">Email:</div>
          <div className="truncate w-full block">{item.email}</div>
        </div>
        <div className="lg:col-span-1 flex text-gray-500">
          <div className="lg:hidden w-full">No. of members:</div>
          <div className="truncate w-full block">{item.total_member}</div>
        </div>
        <div className="lg:col-span-2 flex text-gray-500">
          <div className="lg:hidden w-full">Domain:</div>
          <div className="truncate w-full block">{item.slug}</div>
        </div>
        <div className="lg:col-span-1 flex text-gray-500">
          <div className="lg:hidden w-full">Created:</div>
          <div className="truncate w-full block">{date(item.created_at)}</div>
        </div>
        <div className="lg:col-span-2 flex gap-4 justify-between lg:justify-center">
          {item.status === "pending" && (
            <button
              className="text-primary"
              onClick={() => changeStatus("approved")}
            >
              Approve
            </button>
          )}
          <button className="text-error" onClick={() => destoryCommunity()}>
            Remove
          </button>
        </div>
      </div>
    );
  }
};

export default CommunityItem;
