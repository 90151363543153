import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { list } from "../../api/messages";
import MessageItem from "../../components/MessageItem";
import NoItems from "../../components/NoItems";

const Messages = () => {
  const { community_slug, member_id } = useParams();
  const [data, setData] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await list({
        community_slug,
        member_id,
      });
      setUser(response.data.user);
      setData(response.data.chats);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {/* Message list */}
      <div className="lg:border border-gray-300 lg:p-3 rounded-lg striped">
        <MessageItem key={0} heading={true} user={user} />
        {isLoading === true && (
          <>
            <MessageItem key={1} loading={true} />
            <MessageItem key={2} loading={true} />
          </>
        )}
        {isLoading === false &&
          (typeof data?.data === "undefined" || data?.data.length === 0) && (
            <NoItems />
          )}
        {isLoading === false &&
          typeof data?.data !== "undefined" &&
          data?.data.map((item) => (
            <MessageItem key={item.id} item={item} fetchData={fetchData} community_slug={community_slug} />
          ))}
      </div>
    </>
  );
};

export default Messages;
