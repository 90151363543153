import React, { useState, useEffect } from "react";
import { destory, changeManager, channelUsers } from "../api/channels";

const MessageItem = ({
  heading = false,
  loading = false,
  item = false,
  community_slug,
  fetchData,
  member_selection = [],
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [editManager, setEditManager] = useState();
  const [manager, setManager] = useState(item.manager_id);
  const [showChannelMembers, setShowChannelMembers] = useState(false);
  const [channelMembers, setChannelMembers] = useState([]);

  const updateManager = async () => {
    try {
      setIsLoading(true);
      await changeManager({
        community_slug: community_slug,
        id: item.id,
        data: { manager_id: manager },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      fetchData();
    }
  };

  const destoryChannel = async () => {
    try {
      if (window.confirm(`Are you sure to delete ${item.name} channel?`)) {
        setIsLoading(true);
        await destory({ community_slug: community_slug, id: item.id });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      fetchData();
    }
  };

  const channelUsersList = async () => {
    try {
      setIsLoading(true);
      const data = await channelUsers({
        community_slug: community_slug,
        id: item.id,
      });
      setChannelMembers(data.data);
      setIsLoading(false);
      setShowChannelMembers(true);
    } catch (error) {
      console.log(error);
    }
  };

  if (heading === true) {
    return (
      <div className="hidden lg:grid grid-cols-10 font-semibold mb-7 gap-6 px-2">
        <div className="col-span-2">Channel Name</div>
        <div className="col-span-3">Manager Name</div>
        <div className="col-span-2 text-center">Members</div>
        <div className="col-span-3 text-center">Action</div>
      </div>
    );
  } else if (loading) {
    return (
      <div className="grid grid-cols-10 py-3 gap-6 px-2 rounded-lg animate-pulse">
        <div className="col-span-2">
          <div className="h-4 w-52 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-3">
          <div className="h-4 w-52 bg-gray-400 rounded-md"></div>
        </div>
        <div className="col-span-2">
          <div className="h-4 w-20 bg-gray-400 rounded-md mx-auto"></div>
        </div>
        <div className="col-span-3 flex">
          <div className="h-4 w-32 bg-gray-400 rounded-md mx-auto"></div>
          <div className="h-4 w-32 bg-gray-400 rounded-md mx-auto"></div>
        </div>
      </div>
    );
  } else if (item) {
    return (
      <>
        <div
          className={`grid grid-rows-4 lg:grid-rows-1 lg:grid-cols-10 py-3 gap-2 lg:gap-6 px-2 rounded-lg items-center`}
        >
          <div className="lg:col-span-2 break-words lg:max-w-[70vw] flex">
            <div className="lg:hidden w-full">Channel Name:</div>
            <div className="w-full">{item.name}</div>
          </div>
          <div className="lg:col-span-3 flex">
            <div className="lg:hidden w-full">Manager Name:</div>
            <div className="w-full">
              {item.channel_type != "default" &&
                editManager != item.id &&
                item.creator}
              {editManager &&
                editManager == item.id &&
                item.channel_type != "default" &&
                member_selection.length > 0 && (
                  <select
                    name="manager"
                    id="manager"
                    className="select select-bordered"
                    value={manager}
                    onChange={(e) => setManager(e.target.value)}
                    required
                  >
                    <option value="">Select</option>
                    {member_selection.map((member) => {
                      return (
                        <option value={member.value} key={member.value}>
                          {member.label}
                        </option>
                      );
                    })}
                  </select>
                )}
            </div>
          </div>
          <div className="lg:col-span-2 text-left lg:text-center flex">
            <div className="lg:hidden w-full">Members:</div>
            <div className="w-full">
              {item.channel_type != "default" && (
                <>
                  {showChannelMembers == false && (
                    <button
                      className="btn btn-link text-primary no-underline hover:no-underline p-0"
                      onClick={() => channelUsersList()}
                    >
                      Show Members
                    </button>
                  )}
                  {showChannelMembers == true && (
                    <button
                      className="btn btn-link text-primary no-underline hover:no-underline p-0"
                      onClick={() => setShowChannelMembers(false)}
                    >
                      Hide Members
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="lg:col-span-3 flex gap-1 lg:items-center justify-between lg:justify-start lg:justify-center">
            {item.channel_type != "default" && (
              <>
                {editManager != item.id && (
                  <button
                    className="btn btn-link text-primary no-underline hover:no-underline"
                    onClick={() => setEditManager(item.id)}
                  >
                    Change manager
                  </button>
                )}
                {editManager == item.id && (
                  <button
                    className="btn btn-link text-primary no-underline hover:no-underline"
                    onClick={() => updateManager()}
                  >
                    Update manager
                  </button>
                )}
                <button
                  onClick={() => destoryChannel()}
                  className="btn btn-link text-error no-underline hover:no-underline"
                >
                  Remove
                </button>
              </>
            )}
          </div>
          {showChannelMembers == true && (
            <>
              {isLoading && <p>Loading...</p>}

              {channelMembers.length == 0 && <p>No channel members found.</p>}

              {channelMembers.length > 0 && (
                <div className="grid grid-cols-2 lg:grid-cols-4 lg:col-span-10 p-4 bg-gray-200 rounded-lg">
                  {channelMembers.map((member) => {
                    return (
                      <div className="" key={member.id}>
                        {member.name}
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
};

export default MessageItem;
