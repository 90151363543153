import React, { useContext, useEffect, useState } from "react";
import Logo from "../assets/images/orrrg-logo.png";
import { AuthContext } from "../context/AuthContext";
import { getFromLocalStorage } from "../utils/localStorage";
import { Link, useParams } from "react-router-dom";
import MenuSVG from "../assets/images/menu.svg";
import UserProfileSVG from "../assets/images/user-profile.svg";
import LogoutSVG from "../assets/images/logout.svg";

const Topbar = () => {
  const { logout, authToken } = useContext(AuthContext);
  const [community, setCommunity] = useState(null);
  const { community_slug } = useParams();
  useEffect(() => {
    const communityData = getFromLocalStorage("community");
    setCommunity(communityData);
  }, []);
  return (
    <div className="bg-orgGray py-2 lg:py-4">
      <div className="container flex justify-between items-center">
        {authToken ? (
          <label
            htmlFor="my-drawer-2"
            className="btn btn-ghost drawer-button lg:hidden"
          >
            <img src={MenuSVG} alt="Search" className="h-8 w-8" />
          </label>
        ): <div className="block lg:hidden"></div>}
        {community_slug && community && community.name ? (
          <div className="text-xl lg:text-3xl font-semibold hidden lg:flex">
            {community.name}
          </div>
        ) : (
          <div className="hidden lg:inline-flex">&nbsp;</div>
        )}

        <img src={Logo} className="w-24 lg:w-36" />
        {authToken ? (
          <div className="flex items-center">
            {community_slug && community && community.name && (
              <>
                <div className="text-xl lg:text-3xl font-semibold lg:hidden pr-2">
                  {community.name}
                </div>
                <Link
                  to={`${community.slug}/manage-account`}
                  className="btn text-gray-400 text-xl btn-ghost px-0 lg:px-3 hidden lg:flex"
                >
                  <span className="hidden lg:inline-block">Manage Account</span>
                  <img
                    src={UserProfileSVG}
                    alt="Search"
                    className="h-8 lg:hidden"
                  />
                </Link>
              </>
            )}
            <button
              className="btn text-primary lg:text-xl btn-ghost hidden lg:inline-block"
              onClick={logout}
            >
              <span className="hidden lg:inline-block">Sign Out</span>
              <img src={LogoutSVG} alt="Search" className="h-8 w-8 lg:hidden" />
            </button>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Topbar;
