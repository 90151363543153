import React, { useState } from "react";
import { destory, comments, destoryComment } from "../api/posts";
import { date, isVideo, isPDF } from "../helpers";
import NoItems from "./NoItems";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import LinkIcon from "../assets/images/open-in-new-window.svg";

const MessageItem = ({
  loading = false,
  item = false,
  community_slug,
  fetchData,
  page,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAttchments, setShowAttchments] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [commentsData, setComments] = useState(true);
  const [hideItem, setHideItem] = useState(false);

  const deletePost = async () => {
    setIsLoading(true);
    try {
      if (window.confirm("Aer you sure want to delete this post?")) {
        await destory({
          community_slug: community_slug,
          id: item.id,
        });
        fetchData(page);
        setHideItem(true);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteComment = async (id, index) => {
    setIsLoading(true);
    try {
      if (window.confirm("Aer you sure want to delete this comment?")) {
        const response = await destoryComment({
          community_slug: community_slug,
          id: id,
        });

        setComments((commentsData) =>
          commentsData.filter((comment, i) => i !== index)
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getComments = async () => {
    if (showComments == true) {
      setShowComments(false);
    } else {
      setShowAttchments(false);
      setShowComments(true);
      setIsLoading(true);
      try {
        const response = await comments({
          community_slug: community_slug,
          post_id: item.id,
        });
        setComments(response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col gap-3 py-3 px-2 rounded-lg animate-pulse border border-gray-300 p-3">
        <div className="flex gap-4">
          <div className="w-20 h-4 bg-gray-400 rounded-md"></div>
          <div className="w-16 h-4 bg-gray-400 rounded-md"></div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="w-10/12 h-4 bg-gray-400 rounded-md"></div>
          <div className="w-8/12 h-4 bg-gray-400 rounded-md"></div>
          <div className="w-full h-4 bg-gray-400 rounded-md"></div>
        </div>
        <div className="flex gap-4">
          <div className="w-20 h-4 bg-gray-400 rounded-md"></div>
          <div className="w-16 h-4 bg-gray-400 rounded-md"></div>
          <div className="w-16 h-4 bg-gray-400 rounded-md"></div>
        </div>
      </div>
    );
  } else if (item && hideItem == false) {
    return (
      <div className="flex flex-col gap-3 py-3 px-2 rounded-lg border border-gray-300 p-3">
        <div className="flex gap-4">
          <div className="font-semibold flex items-center gap-2">
            {item.channel.channel_name}
            <div className="text-gray-400 text-sm font-normal">
              ({item.channel.channel_type == "default" ? "Default" : "Custom"})
            </div>
          </div>
          <div className="text-gray-400">{item.created_by}</div>
        </div>
        <div className="break-words max-w-[75vw]">{item.text}</div>
        <div className="flex gap-4">
          {item.media.length > 0 && (
            <div
              className="px-0 btn btn-ghost text-primary"
              onClick={() => {
                if (showAttchments == true) {
                  setShowAttchments(false);
                } else {
                  setShowComments(false);
                  setShowAttchments(true);
                }
              }}
            >
              {showAttchments ? "Hide" : "View"} Attachment ({item.media.length}
              )
            </div>
          )}
          <div
            className="px-0 btn btn-ghost text-primary"
            onClick={() => getComments()}
          >
            {showComments == true ? "Hide" : "View"} Comments
          </div>
          <div
            className="px-0 btn btn-ghost text-error"
            onClick={() => deletePost()}
          >
            {item.trashed ? " Approve Deletetion" : "Delete"}
          </div>
        </div>
        {showAttchments && (
          <div>
            {item.media.length == 0 && <NoItems />}
            {item.media.length > 0 && (
              <>
                <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
                  {item.media.map((media) => {
                    if (!isVideo(media.url) && !isPDF(media.url)) {
                      return (
                        <a href={media.url} key={media.id}>
                          <img
                            alt={media.id}
                            src={media.url}
                            className="max-h-60"
                          />
                        </a>
                      );
                    }
                  })}
                </LightGallery>

                {item.media.map((media) => {
                  if (isVideo(media.url)) {
                    return (
                      //   <video
                      //     preload="metadata"
                      //     key={media.id}
                      //     src={media.url}
                      //     className="max-w-sm rounded-xl mt-2"
                      //     controls
                      //   >
                      //     Your browser does not support the video tag.
                      //   </video>
                      <a
                        href={media.url}
                        target="_blank"
                        className="w-36 h-28 flex justify-center rounded-xl items-center bg-gray-500 text-white"
                      >
                        Video{" "}
                        <img src={LinkIcon} alt="Search" className="h-6" />
                      </a>
                    );
                  }
                })}

                {item.media.map((media) => {
                  if (isPDF(media.url)) {
                    return (
                      <a
                        href={media.url}
                        target="_blank"
                        className="w-36 h-28 flex justify-center rounded-xl items-center bg-gray-500 text-white"
                      >
                        PDF{" "}
                        <img src={LinkIcon} alt="Search" className="h-6" />
                      </a>
                    );
                  }
                })}
              </>
            )}
          </div>
        )}
        {showComments && (
          <div>
            <h3 className="mb-6 font-semibold">Post Comments</h3>
            {commentsData.length == 0 && <NoItems />}
            {commentsData.length > 0 && (
              <div className="flex flex-col gap-5">
                {commentsData.map((comment, index) => {
                  return (
                    <div className="border-b pb-2">
                      <div className="text-gray-500">
                        {comment.user.name}, {date(comment.created_at)}
                      </div>
                      <div>{comment.comment}</div>
                      <div
                        className="px-0 btn btn-ghost text-error"
                        onClick={() => deleteComment(comment.id, index)}
                      >
                        Delete
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
};

export default MessageItem;
